.viewArchiveTwo:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 0.5px;
    background-color: #64ffda;
    transition: all 0.25s ease-in-out;
  }
  
  .viewArchiveTwo:hover:after {
    width: 100%;
  }