.contentContainer {
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.5s ease-in-out;
}
  
.contentContainer.show {
    opacity: 1;
    transform: translateY(0);
}

.spanOneHoverClass:after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0;
    height: 0.5px;
    background-color: #64ffda;
    transition: all 0.25s ease-in-out;
}

.spanOneHoverClass:hover:after {
    width: 100%;
}
